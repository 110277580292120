<template>
  <div class="testbody">
    <!-- 顶部 -->
    <div class="disp_flex">
      <!-- 左侧 -->
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <el-radio-group v-model="formInline.radio">
            <el-radio-button label="0">全部</el-radio-button>
            <el-radio-button label="1">本月</el-radio-button>
            <el-radio-button label="2">近3月</el-radio-button>
            <el-radio-button label="3">近6月</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="检查日期：">
          <el-date-picker
            v-model="formInline.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <!-- 右侧按钮 -->
      <div>
        <el-button class="color-main a1" type="primary" @click="adshow = true">
          <i class="fa fa-folder"></i>HIS导入
        </el-button>
        <!-- <el-button class="color-main a1" type="primary" @click="add">
          <i class="fa fa-plus-circle"></i>新增
        </el-button> -->
      </div>
    </div>
    <!-- 中间选择 -->
    <div>
      <el-tabs
        v-model="activeName"
        type="card"
        class="demo-tabs"
        @tab-click="handleClick"
      >
        <el-tab-pane
          :label="item.name"
          :name="item.code"
          v-for="item in dictList[200000]"
          :key="item.code"
        >
          <!-- 表格 -->
          <div class="disp_flex">
            <!-- 左侧表格 -->
            <div style="width: 35%">
              <el-table
                :data="tableData"
                style="width: 100%"
                border
                @row-click="Rowclick"
                v-loading="loading"
              >
                <el-table-column prop="created_time" label="检查日期" />
                <el-table-column prop="dict_type_str" label="化验大项" />
                <el-table-column
                  prop="address"
                  fixed="right"
                  label="操作"
                  width="120"
                >
                  <template #default="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="modParient(scope.row)"
                    >
                      <span class="txfonts modify">改</span>
                    </el-button>
                    <el-popconfirm
                      title="是否确定删除?"
                      @confirm="delParient(scope.row)"
                    >
                      <template #reference>
                        <el-button type="text" size="small">
                          <span class="txfonts del">删</span>
                        </el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <div class="disp_flex">
                <div></div>
                <el-pagination
                  small
                  background
                  layout="prev, pager, next"
                  :total="total"
                  class="mt-4"
                  @current-change="currentchange"
                />
              </div>
            </div>
            <!-- 右侧数据 -->
            <div class="rigthtabs">
              <!-- 顶部 -->
              <div class="disp_flex">
                <div></div>
                <!-- 右侧按钮 -->
                <div>
                  <el-button
                    class="color-main a1"
                    type="primary"
                    @click="square"
                  >
                    <i class="fa fa-pencil-square"></i>修改
                  </el-button>
                  <el-button class="color-main a2" type="primary">
                    <i class="fa fa-trash"></i>删除
                  </el-button>
                </div>
              </div>
              <!-- 中间内容 -->
              <div class="disp_flex" style>
                <!-- 左侧 -->
                <div>
                  <el-form :model="ruleForm" class="demo-form-inline">
                    <el-form-item label="送检时间：">{{
                      ruleForm.examine_time
                    }}</el-form-item>
                    <el-form-item label="检查部位：">{{
                      ruleForm.examine_part
                    }}</el-form-item>
                    <el-form-item label="检查所见：">{{
                      ruleForm.examine_result
                    }}</el-form-item>
                    <el-form-item label="上传文件：">
                      <div class="formimg">
                        <img
                          :src="item.url"
                          alt
                          v-for="(item, index) in ruleForm.file_list"
                          :key="index"
                        />
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
                <!-- 右侧 -->
                <div>
                  <el-form :model="ruleForm" class="demo-form-inline">
                    <el-form-item label="结果提示：">{{
                      ruleForm.examine_result_intro
                    }}</el-form-item>
                    <el-form-item label="检查结果：">{{
                      ruleForm.examine_data
                    }}</el-form-item>
                    <el-form-item label="报告时间：">{{
                      ruleForm.report_time
                    }}</el-form-item>
                    <el-form-item label="就诊医师：">{{
                      ruleForm.doctor_name
                    }}</el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 导入弹窗 -->
    <div>
      <BaseDialog
        :title="'修改通路彩超'"
        :isshow="adshow"
        @handleShow="
          (val) => {
            adshow = val
          }
        "
        width="40%"
        height="auto"
      >
        <div class="DialogDR">
          <el-form :model="form" label-width="100px" :rules="rules">
            <el-form-item label="检查时间：" prop="username">
              <el-date-picker v-model="form.rules" type="date" />
            </el-form-item>
            <el-form-item label="检查部位：" prop="username">
              <el-input v-model="form.input" />
            </el-form-item>
            <el-form-item label="检查所见：">
              <el-input
                v-model="textarea2"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
              />
            </el-form-item>
            <el-form-item label="检查结果：" prop="username">
              <el-select v-model="value" class="m-2">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="结果提示：">
              <el-select v-model="value" class="m-2">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="上传文件：">
              <el-upload
                class="avatar-uploader"
                list-type="picture-card"
                :on-change="handleChange"
                :on-preview="handlePictureCardPreview"
                :auto-upload="false"
                :limit="6"
              >
                <span v-for="(item, index) in fileList" :key="index">
                  <img :src="item.url" />
                </span>
                <span>
                  <el-icon class="avatar-uploader-icon">
                    <div class="uploadicos">
                      <i class="fa fa-camera"></i>
                    </div>

                    <div style="font-size: 14px">
                      添加照片{{ fileList.length }}/6
                    </div>
                  </el-icon>
                </span>
              </el-upload>
            </el-form-item>
            <el-form-item label="报告时间：">
              <el-date-picker v-model="form.rules" type="date" />
            </el-form-item>
            <el-form-item label="诊断医师：" prop="username">
              <el-input v-model="form.input" />
            </el-form-item>
          </el-form>
        </div>
        <!-- 下划线 -->
        <el-divider />
        <!-- 底部按钮 -->
        <div class="disp_flex">
          <div></div>
          <div class="disp_flex">
            <el-button @click="adshow = false">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
          </div>
        </div>
      </BaseDialog>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { gitDictList, TimeYear } from '@/utils/tool'
import BaseDialog from '@/components/Dialog/index.vue'
import service from '@/utils/request'
export default {
  components: {
    BaseDialog,
  },
  setup() {
    const state = reactive({
      formInline: {
        radio: 0,
      },
      dictList: {},
      activeName: '',
      actDialyse: {},
      total: 0,
      loading: true,
      adshow: false,
      dialysisTypeWin: 0,
      dialysisTypeLeft: 0,
      form: {
        radio1: '1',
      },
      ruleForm: {},
      current: 1,
      tableData: [],
      RtableData: [],
      fileList: [],
    })
    onMounted(() => {
      gitDict()
      tobody()
    })
    // 获得字典
    const gitDict = async () => {
      state.dictList = await gitDictList(['200000'])
      let data = {
        name: '全部',
      }
      if (state.dictList[200000]) {
        state.dictList[200000].unshift(data)
      }
    }
    // 获取列表
    const tobody = async () => {
      state.loading = true
      let data = {
        size: 10,
        current: state.current,
        type_code: state.activeName,
      }
      let res = await service.post('/api/examine/list', data)
      if (res.code === 0) {
        if (res.data.total > 0) {
          state.tableData = res.data.list
          state.total = res.data.total
          detail(res.data.list[0].id)
        } else {
          state.tableData = []
          state.total = 0
        }
      }
      state.loading = false
    }
    // 选择切换
    const handleClick = () => {
      tobody()
    }
    // 表单验证
    const rules = {
      username: [
        {
          required: true,
          message: '请输入用户名',
          trigger: 'blur',
        },
      ],
    }
    // 左侧表格点击
    const Rowclick = (row) => {
      detail(row.id)
    }
    // 右侧表格列表
    const detail = async (id) => {
      let res = await service.post('/api/examine/detail', { id: id })
      if (res.code === 0) {
        state.ruleForm = res.data.examine
        state.ruleForm.report_time = TimeYear(state.ruleForm.report_time)
        state.ruleForm.examine_time = TimeYear(state.ruleForm.examine_time)
      } else {
        state.ruleForm = {}
      }
    }
    // 点击分页
    const currentchange = (index) => {
      state.current = index
      tobody()
    }
    // 修改
    const square = () => {
      state.adshow = true
    }
    // 上传照片
    const handleChange = async (e) => {
      console.log(e)
      let fileObj = e.raw
      let form = new FormData()
      form.append('img_file', fileObj)
      form.append('type', 'avatar')
      let res = await service.post('/api/common/upload_img', form)
      console.log(res)
      if (res.code === 0) {
        console.log(res)
        state.fileList.push(res)
      }
    }

    const tagName = ref('examine')
    return {
      ...toRefs(state),
      gitDict,
      tagName,
      handleClick,
      rules,
      tobody,
      Rowclick,
      detail,
      currentchange,
      square,
      handleChange,
    }
  },
}
</script>

<style scoped lang="scss">
.testbody {
  height: 706px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  &:deep(.el-divider--horizontal) {
    margin: 8px 0;
  }
}
.disp_flex {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
}
.a1 {
  background: rgba(49, 102, 174, 0.1);
  opacity: 1;
  border: none;
  color: #3166ae;
}
.a2 {
  background: rgba(255, 62, 62, 0.2);
  opacity: 1;
  border: none;
  color: #ff3e3e;
}
.modify {
  background-color: #3166ae;
}
.del {
  background-color: #ffecec;
  color: #ff6b6b;
  font-weight: 700;
}
.icons {
  width: 18px;
  height: 18px;
  background: #3166ae;
  opacity: 1;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  cursor: pointer;
}
.DialogDR {
  margin: 8px 0;
}
.Dialogadd {
  margin: 8px 0;
  height: 700px;
  overflow-y: scroll;
  .el-form {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    &:deep(.el-form-item__content) {
      width: 200px !important;
    }
  }
}
.arrowup {
  color: #ff3d49;
}
.arrowdown {
  color: #2984ef;
}
.rigthtabs {
  width: calc(100% - 38%);
  height: 408px;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 1px solid #999999;
  padding: 8px;
  &:deep(.el-form .el-form-item__label) {
    font-family: 'Source Han Sans CN-Medium', 'Source Han Sans CN';
    font-weight: 700;
    color: #000000;
  }
}
.formimg {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  img {
    width: 120px;
    height: 120px;
    margin: 3px;
  }
}
</style>

<style>
.avatar-uploader .avatar {
  width: 146px;
  height: 146px;
  display: block;
}
.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 146px;
  height: 146px;
  text-align: center;
  margin-top: 0;
  flex-wrap: wrap;
  align-content: flex-start;
}
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}
.avatar-uploader-icon {
  border: 1px solid #ccc;
}
.uploadicos {
  width: 80px;
  margin-top: -11px;
}
</style>
